// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chi-siamo-js": () => import("./../../../src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-contattaci-js": () => import("./../../../src/pages/contattaci.js" /* webpackChunkName: "component---src-pages-contattaci-js" */),
  "component---src-pages-formazione-js": () => import("./../../../src/pages/formazione.js" /* webpackChunkName: "component---src-pages-formazione-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-servizi-assistenza-point-js": () => import("./../../../src/pages/servizi/assistenza-point.js" /* webpackChunkName: "component---src-pages-servizi-assistenza-point-js" */),
  "component---src-pages-servizi-assistenza-tecnico-legale-js": () => import("./../../../src/pages/servizi/assistenza-tecnico-legale.js" /* webpackChunkName: "component---src-pages-servizi-assistenza-tecnico-legale-js" */),
  "component---src-pages-servizi-consulenza-js": () => import("./../../../src/pages/servizi/consulenza.js" /* webpackChunkName: "component---src-pages-servizi-consulenza-js" */),
  "component---src-pages-servizi-gestione-rifiuti-js": () => import("./../../../src/pages/servizi/gestione-rifiuti.js" /* webpackChunkName: "component---src-pages-servizi-gestione-rifiuti-js" */),
  "component---src-pages-servizi-index-js": () => import("./../../../src/pages/servizi/index.js" /* webpackChunkName: "component---src-pages-servizi-index-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */)
}

